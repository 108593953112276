export default defineNuxtRouteMiddleware(async () => {
  const app = useNuxtApp();

  if (app.$user.session.isAuthorized) {
    return;
  }

  try {
    await app.$user.fetchAndStoreCurrentUser();
  } catch (e) {
    console.error(e);
  }
});
